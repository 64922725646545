import React from "react"
import { AnimatePresence } from "framer-motion"
// custom typefaces

// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode={"wait"}>{element}</AnimatePresence>
)
